// This file will contain all the functions needed to load all the display components
import * as adManager from "./components/AdManager";
import { adUnitConfigType, adUnitType, initConfigType } from "./types";

/**
 * Initialize the display module
 *
 * @param initConfig configurations for the display module
 */
export async function init(initConfig: initConfigType): Promise<void> {
  return new Promise<void>((resolve) => {
    adManager.init(initConfig.gamConfig);
    // todo: get targeting from URL params.
    for (const [key, value] of Object.entries(initConfig.targeting)) {
      adManager.setTargeting(key, value);
    }
    resolve();
  });
}

/**
 * Add an ad unit
 *
 * @param adUnitConfig ad unit configuration
 * @returns
 */
export async function addAdUnit(adUnitConfig: adUnitConfigType) {
  const { code, sizes = [], divId, type, triggers } = adUnitConfig;
  switch (type) {
    case "interstitial":
      return adManager.addInterstitialAdUnit(
        code,
        triggers?.navBar,
        triggers?.unhideWindow,
      );
    default: // banner ads
      return adManager.addAdUnit(code, sizes, divId);
  }
}

/**
 * Add rampjs class to display ad unit div.
 *
 * @param divId the div id of the ad unit
 * @returns void
 */
export function addRampJsClass(divId: string) {
  const div = document.getElementById(divId);
  const label = "rampjs-label";
  if (div && !div.classList.contains(label)) {
    div.classList.add(label);
  }
}

/**
 * Injects the Advertisement CSS and it should only be injected only once.
 *
 * @returns void
 */
export function injectAdvertisementCSS() {
  const style = document.createElement("style");
  const css = `
    .rampjs-label::before {
        content: "Advertisement";
        display: block;
        margin-bottom: .25rem;
        text-align: center;
        font-size: .75rem;
    }
  `;

  // Append the CSS string to the <style> element
  style.appendChild(document.createTextNode(css));

  // Append the <style> element to the <head> of the document
  document.head.appendChild(style);
}

/**
 * Refresh the ad unit
 *
 * @param adUnit
 * @returns
 */
export function refreshAdUnit(adUnit: adUnitType) {
  return adManager.refreshAdUnit(adUnit.adUnit.slot);
}

/**
 * Enable ads
 */
export function enableAds() {
  adManager.enableAds();
}
