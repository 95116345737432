// src/logger.ts
import * as uuid from "uuid";
function useLocalStorage(key, initialValue) {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : (window.localStorage.setItem(key, JSON.stringify(initialValue)), initialValue);
}
async function emitEvent(event, useNavigator = false) {
  const dplServerUrl = "https://soflopxl.com/dplpxs";
  if (useNavigator) {
    if (!navigator.sendBeacon) {
      console.error(
        "navigator.sendBeacon is not supported - using fetch instead"
      );
    } else {
      const queued = navigator.sendBeacon(
        dplServerUrl,
        JSON.stringify([event])
      );
      if (queued)
        return;
    }
  }
  await fetch(dplServerUrl, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify([event])
  });
}
function createLogger(opts) {
  const serverSide = opts.serverSide ?? typeof window === "undefined";
  let [s1sid, referrer, url] = [];
  if (!serverSide) {
    s1sid = useLocalStorage("s1sid", uuid.v4());
    referrer = document.referrer;
    url = window.location.href;
  }
  const onError = opts.onError ?? ((e) => console.error(e));
  const onSuccess = opts.onSuccess ?? (() => void 0);
  const assignMetadata = (event) => Object.assign(event, {
    product: opts.product,
    payload: { ...event.payload, s1sid, referrer, url }
  });
  if (opts.sendInitEvent) {
    emitEvent(assignMetadata({ event: opts.initEventName }), opts.useNavigator).then(() => onSuccess()).catch((e) => onError(e));
  }
  return {
    log: (event, emit = true) => {
      if (emit) {
        emitEvent(assignMetadata(event), opts.useNavigator).then(() => onSuccess()).catch((e) => onError(e));
      }
    }
  };
}
export {
  createLogger
};
