import type { RSoC } from "@rampjs/schema";
import type { AdLoadedCallback, Block } from "custom-search-ads";
import { rampJsUnitLoaded } from "./core";
import * as logger from "./logger";

let callbackRecieved = false;

export const EXTERNAL_SRC = "https://www.google.com/adsense/search/ads.js";

export function buildCallback(unit: RSoC.Unit): AdLoadedCallback {
  return (containerName, adsLoaded, isExperimentVariant, callbackOptions) => {
    console.debug(`adLoadedCallback for container: ${containerName}`);
    callbackRecieved = true;
    try {
      const pingback = new URL(unit.callbackUrl);

      pingback.searchParams.append(unit.adsLoadedParam, adsLoaded.toString());
      pingback.searchParams.append(
        unit.callbackOptionsParam,
        JSON.stringify(callbackOptions),
      );

      fetch(pingback, { keepalive: true })
        .then()
        .catch((err) => console.error(err));
    } catch (error) {
      logger._errorEvent(error);
    }

    // Dispatch ad loaded event
    const event = rampJsUnitLoaded(unit.container, "csa");
    window.dispatchEvent(event);
  };
}

export function init(pageOptions: RSoC.PageOptions, blocks: Block[]) {
  _googCsa("relatedsearch", pageOptions, ...blocks);
}

export function insertScripts() {
  if (!window._csaStub && typeof window._googCsa === "undefined") {
    // IIFE inline script
    /* eslint-disable */
    // @ts-ignore
    // prettier-ignore
    (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(arguments)},g[o]['t']=1*new Date()})(window,'_googCsa');
    /* eslint-enable */
    window._csaStub = true;
  }

  // guard against duplicate insertion
  if (document.querySelector(`script[src="${EXTERNAL_SRC}"]`) !== null) return;

  // load external script
  const external = document.createElement("script");
  external.src = EXTERNAL_SRC;
  document.head.prepend(external);
}

export function parseUnit(unit: RSoC.Unit): Block {
  return {
    adLoadedCallback: buildCallback(unit),
    container: unit.container,
    relatedSearches: unit.relatedSearches,
    width: unit.width,
  };
}

export function checkForNoCallbacks(pingbackUrl: string) {
  addEventListener("beforeunload", () => {
    if (!callbackRecieved) {
      sendAlertPingback(pingbackUrl, "page_exit");
    }
  });
}

export function handleMissingDivs(pingbackUrl: string) {
  sendAlertPingback(pingbackUrl, "missing_divs");
}

function sendAlertPingback(pingbackUrl: string, reason: string) {
  const pingback = new URL(pingbackUrl);
  pingback.searchParams.append("rsonc_no_callback", reason);
  fetch(pingback, { keepalive: true })
    .then()
    .catch((err) => console.error(err));
}
